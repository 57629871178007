import { Action, Dispatch } from "@reduxjs/toolkit";
import { ColdexTestAPI } from "../../../apis/apiColdex/ColdexTestAPI";
import {
  MESSAGE_ACTION_TYPE_GET,
  MESSAGE_CONTEXT__CATEGORY,
  MESSAGE_ERROR_TIMEOUT,
} from "../../../utils/constants/message-constants";
import { IApiResult } from "../../../utils/interfaces/store/IApiResponse";
import { Category } from "../../../utils/types/Categorys";
import {
  startLoadingCategorys,
  setCategoryxColdex,
  setError,
} from "./categoryXColdex";
/**
* Esta es una función que recupera categorías basadas en una ID de Coldex dada y envía acciones a
  * actualizar el estado en consecuencia.
  * @param {number} idcoldex - El parámetro `idcoldex` es un número que representa la ID de un Coldex
  * Esta función utiliza este ID para obtener una lista de categorías asociadas con ese objeto Coldex
  * desde un API.
  * @returns Una función que toma un parámetro numérico y devuelve una función asíncrona que
  * envía acciones para comenzar a cargar categorías y obtener categorías por ID de Coldex desde una API. Si el
  * La llamada API es exitosa, envía una acción para establecer las categorías recuperadas en el store. Sí hay
  * es un error, envía una acción para establecer el mensaje de error en el store.
 */
export const getCategorysbyColdex = (idcoldex: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(startLoadingCategorys());
    try {
      const { data } = await ColdexTestAPI.get<IApiResult<Array<Category>>>(
        "adminColdex/coldex/CategoriesxColdex/" + idcoldex
      );
      if (data.dataError) {
        dispatch(
          setError({
            error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT__CATEGORY}`,
            iserror: true,
            shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT__CATEGORY}`,
            open: true,
          })
        );
      } else {
        dispatch(
          setCategoryxColdex({
            resultObject: data.resultObject,
            resultMessage: data.resultMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_GET} en ${MESSAGE_CONTEXT__CATEGORY}`,
          iserror: true,
          shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT__CATEGORY}`,
          open: true,
        })
      );
    }
  };
};
/**
 * Esta es una función que crea categorías usando una llamada API y envía un mensaje de error si
  * hay un problema.
  * @param { Array<Sector>} categorys matriz de objetos de tipo Sector.
  * @returns Una función que toma una matriz de objetos Sector como entrada y devuelve un asincrónico
  * función que envía una acción para crear categorías en ColdexTestAPI. La función maneja
  * errores y envía una acción de error si es necesario.
 */
export const resetError = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch(
      setError({ error: "", iserror: false, shorterror: "", open: false })
    );
  };
};
