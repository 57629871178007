import { SpinnerCircular } from 'spinners-react';
import { COLOR_SPINNER_LOADER_STADISTICS } from '../../utils/constants/text-constants';
/* Este código define un componente funcional de React llamado `Loader`. Importa el componente `SpinnerCircular` de la biblioteca `spinners-react` y algunas otras dependencias. */
const Loader = () => {

  return (
    <div className={"open-PopUp" }>
     <div className="popUp-Content wide2 center">
        <SpinnerCircular size={80} color={COLOR_SPINNER_LOADER_STADISTICS} thickness={180}/>
        <span>Cargando...</span>
     </div>
    </div>
  )
}

export default Loader;