import { Action, Dispatch } from '@reduxjs/toolkit';
import { ColdexTestAPI } from '../../../apis/apiColdex/ColdexTestAPI';
import { MESSAGE_ACTION_TYPE_CREATE, MESSAGE_CONTEXT_ROWDATA, MESSAGE_ERROR_TIMEOUT } from '../../../utils/constants/message-constants';
import { IApi } from '../../../utils/interfaces/store/IApiResponse';
import { RowDataPublicColdex } from '../../../utils/types/RowDataColdex';
import { startLoading,setError,endLoading} from './rowDataPublicColdexSlice';
/**
  * Esta es una función de TypeScript React que publica datos sin procesar en ColdexTestAPI y envía acciones de carga y error según la respuesta.
  * Respuestas @param: un array de objetos RowDataColdex que se enviarán como carga útil en la solicitud POST a ColdexTestAPI (para coldex sectorial) 
  * @returns una Promesa que se resuelve como indefinida.
  */
export const postRawData = (answers:Array<RowDataPublicColdex>) => {
    return async( dispatch:Dispatch<Action> ) => {
        dispatch( startLoading() );
        try {
            const { data } = await ColdexTestAPI.post<IApi<Array<RowDataPublicColdex>>>(`adminColdex/RowDataColdexPublic`,answers);
            dispatch(endLoading());
            if (data.dataError) {
                dispatch(
                  setError({
                    error: `${data.resultMessage} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_ROWDATA}`,
                    iserror: true,
                    shorterror: `${data.shortMessage} en ${MESSAGE_CONTEXT_ROWDATA}`,
                    open: true,
                  })
                );
            }else{
                
            }
        } catch (error) {
            dispatch(setError({ error: `${MESSAGE_ERROR_TIMEOUT} al ${MESSAGE_ACTION_TYPE_CREATE} en ${MESSAGE_CONTEXT_ROWDATA}`, iserror: true, shorterror: `${MESSAGE_ERROR_TIMEOUT} en ${MESSAGE_CONTEXT_ROWDATA}`, open: true }));
        }
    }    
}
/**
 * Esta función restablece el estado de error en una tienda Redux.
  * @returns La función `resetError` está devolviendo una función asíncrona que toma un `dispatch`
  * Funciona como un argumento.
 */
export const resetError = () => {
    return async (dispatch: Dispatch<Action>) => {
        dispatch(setError({ error: "", iserror: false, shorterror: "", open: false }));
    }
}