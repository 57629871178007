import { useAppDispatch } from "../../store/Hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IAlert } from "../../utils/interfaces/app/IAlert";
import {
  DoneOutlineOutlined,
  ErrorOutlineOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import "../Cards/cards.scss";
import "./alert.scss";
import {
  VALIDATE_TYPE_ACTIVE,
  VALIDATE_TYPE_ERROR,
  VALIDATE_TYPE_SUCCESS,
  VALIDATE_TYPE_SUCCESS_ACTIVE,
  VALIDATE_TYPE_WARNING,
} from "../../utils/constants/text-constants";
import { LOGIN_ROUTE } from "../../utils/constants/routes-constants";
import { MESSAGE_CONTEXT_ALERT_NOFOUND } from "../../utils/constants/message-constants";
/* Este es un componente funcional en TypeScript React que muestra un mensaje de alerta con diferentes tipos (éxito, error, advertencia) y acciones (cerrar, ir a la página de inicio). Recibe props de tipo `IAlert` que incluye el tipo de alerta, si está abierta o cerrada, el texto a mostrar, el subtexto y el título. */
const Alert = ({ type, open, setopen, text, subtext }: IAlert) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [openDetails, setopenDetails] = useState<boolean>(false);

  const HandleCloseAction = () => {
    if (text !== undefined) {
      if (
        text.includes(VALIDATE_TYPE_ACTIVE) ||
        type.includes(VALIDATE_TYPE_SUCCESS_ACTIVE)
      ) {
        navigate(LOGIN_ROUTE);
      } else {
        dispatch(setopen());
      }
    } else {
      dispatch(setopen());
    }
  };

  return (
    <div className={open ? "open-PopUpAlert" : "close-PopUpAlert"}>
      <div className="popUpAlert-Content">
        <div className="popUpAlert-Content__title">
          <h3>{type.toLocaleUpperCase()}</h3>
        </div>
        <div className="popUpAlert-Content__contentMessage">
          <div className="popUpAlert-Content__contentMessage_iconandmessage">
            {type.toLocaleUpperCase() === VALIDATE_TYPE_ERROR ? (
              <ErrorOutlineOutlined
                color="error"
                sx={{ fontSize: 40 }}
              ></ErrorOutlineOutlined>
            ) : type.toLocaleUpperCase() === VALIDATE_TYPE_SUCCESS ||
              type.toLocaleUpperCase() === VALIDATE_TYPE_SUCCESS_ACTIVE ? (
              <DoneOutlineOutlined
                color="success"
                sx={{ fontSize: 40 }}
              ></DoneOutlineOutlined>
            ) : (
              type.toLocaleUpperCase() === VALIDATE_TYPE_WARNING && (
                <WarningOutlined
                  color="warning"
                  sx={{ fontSize: 40 }}
                ></WarningOutlined>
              )
            )}

            <p>{text}</p>
          </div>
          <div className="popUpAlert-Content__contentMessage__error">
            {
              type.toLocaleUpperCase() !== VALIDATE_TYPE_SUCCESS && type.toLocaleUpperCase() !== VALIDATE_TYPE_SUCCESS_ACTIVE ? (

                <div>
                  {
                    subtext?.length !== 0 || subtext === undefined  &&
                    <button onClick={(e) => setopenDetails(!openDetails)}>
                      Ver Detalle{" "}
                    </button>
                  }


                  {openDetails && (
                    <p>
                      {subtext?.length === 0
                        ? MESSAGE_CONTEXT_ALERT_NOFOUND
                        : subtext}
                    </p>
                  )}

                </div>

              ) : (
                <></>
              )
            }


          </div>
        </div>
        <div className="popUpAlert-Content__actions">
          {type.toLocaleUpperCase() === VALIDATE_TYPE_ERROR ? (
            <button onClick={() => HandleCloseAction()}>
              {text !== undefined && text.includes(VALIDATE_TYPE_ACTIVE) ? (
                <>PAGINA PRINCIPAL</>
              ) : (
                <>Cerrar</>
              )}
            </button>
          ) : type.toLocaleUpperCase() === VALIDATE_TYPE_SUCCESS ||
            type.toLocaleUpperCase() === VALIDATE_TYPE_WARNING ? (
            <button
              onClick={() => {
                HandleCloseAction();
              }}
            >
              {

                text !== undefined &&
                  text.includes(VALIDATE_TYPE_ACTIVE) ?
                  <>PAGINA PRINCIPAL</> :
                  <>Cerrar</>
              }
            </button>
          ) : (
            type.toLocaleUpperCase() === VALIDATE_TYPE_SUCCESS_ACTIVE && (
              <button
                onClick={() => {
                  HandleCloseAction();
                }}
              >
                PAGINA PRINCIPAL
              </button>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default Alert;
