import React from 'react'
import { useAppSelector } from '../../store/Hooks'
import { resetError as resetErrorCategory } from '../../store/slices/categoryXColdex';
import { resetError as resetErrorCompany } from '../../store/slices/company';
import { resetError as resetErrorParameters } from '../../store/slices/parameters';
import { resetError as resetErrorPermissions } from '../../store/slices/permissionXRol';
import { resetError as resetErrorPollTypes } from '../../store/slices/pollTypes';
import { resetError as resetErrorPollTxColdex } from '../../store/slices/pollxColdex';
import { resetError as resetErrorUserxColdex } from '../../store/slices/userxColdex';
import { resetError as resetErrorSecxUserxColdex } from '../../store/slices/securityxUserxColdex';
import { resetError as resetErrorRowDataPublic } from '../../store/slices/rowDataPublic';
import { RootState } from "../../store/Store";
import Alert from '../Alert/Alerts';
/**
  * La función Errores muestra alertas basadas en estados de error de varias store del  Redux.
  * @returns El componente de errores devuelve una lista de componentes de alerta según el estado de varios segmentos de Redux. Cada componente de alerta muestra un mensaje de error con un título, tipo, texto y subtexto. El tipo del componente de alerta está determinado por si hay un error o no.
  */
const Errors = () => {
  
  const { open: openCategory, dataError: dataErrorCategory, resultMessage: resultMessageCategory, shortMessage: shortMessageCategory } = useAppSelector((state: RootState) => state.categoryxColdex);
  const { open: openCompany, dataError: dataErrorCompany, resultMessage: resultMessageCompany, shortMessage: shortMessageCompany } = useAppSelector((state: RootState) => state.company);
  const { open: openParameters, dataError: dataErrorParameters, resultMessage: resultMessageParameters, shortMessage: shortMessageParameters } = useAppSelector((state: RootState) => state.parameters);
  const { open: openPermissions, dataError: dataErrorPermissions, resultMessage: resultMessagePermissions, shortMessage: shortMessagePermissions } = useAppSelector((state: RootState) => state.permission);
  const { open: openPollType, dataError: dataErrorPollType, resultMessage: resultMessagePollType, shortMessage: shortMessagePollType } = useAppSelector((state: RootState) => state.polltypes);
  const { open: openPollxColdex, dataError: dataErrorPollxColdex, resultMessage: resultMessagePollxColdex, shortMessage: shortMessagePollxColdex } = useAppSelector((state: RootState) => state.pollxColdex);
  const { open: opeUserxColdex, dataError: dataErroUserxColdex, resultMessage: resultMessagUserxColdex, shortMessage: shortMessagUserxColdex } = useAppSelector((state: RootState) => state.userxcoldex);
  const { open: opeSecxUserxColdex, dataError: dataErroSecxUserxColdex, resultMessage: resultMessagSecxUserxColdex, shortMessage: shortMessagSecxUserxColdex } = useAppSelector((state: RootState) => state.userxsecurityxcoldex);
  const { open: opeRowData, dataError: dataErroRowData, resultMessage: resultMessagRowData, shortMessage: shortMessagRowData } = useAppSelector((state: RootState) => state.rowDataPublic);

  return (
    <>
      {
        openCategory &&
        <Alert type={!dataErrorCategory ? 'SUCCESS' : 'ERROR'} open={openCategory} text={shortMessageCategory} setopen={resetErrorCategory} subtext={resultMessageCategory}></Alert>
      }
      {
        openCompany &&
        <Alert type={!dataErrorCompany ? 'SUCCESS' : 'ERROR'} open={openCompany} text={shortMessageCompany} setopen={resetErrorCompany} subtext={resultMessageCompany}></Alert>
      }
      {
        openParameters &&
        <Alert type={!dataErrorParameters ? 'SUCCESS' : 'ERROR'} open={openParameters} text={shortMessageParameters} setopen={resetErrorParameters} subtext={resultMessageParameters}></Alert>
      }
      {
        openPermissions &&
        <Alert type={!dataErrorPermissions ? 'SUCCESS' : 'ERROR'} open={openPermissions} text={shortMessagePermissions} setopen={resetErrorPermissions} subtext={resultMessagePermissions}></Alert>
      }
       {
        openPollType &&
        <Alert type={!dataErrorPollType ? 'SUCCESS' : 'ERROR'} open={openPollType} text={shortMessagePollType} setopen={resetErrorPollTypes} subtext={resultMessagePollType}></Alert>
      }
      {
        openPollxColdex &&
        <Alert type={!dataErrorPollxColdex ? 'SUCCESS' : 'ERROR'} open={openPollxColdex} text={shortMessagePollxColdex} setopen={resetErrorPollTxColdex} subtext={resultMessagePollxColdex}></Alert>
      }
      {
        opeUserxColdex &&
        <Alert type={!dataErroUserxColdex ? 'SUCCESS' : 'ERROR'} open={opeUserxColdex} text={shortMessagUserxColdex} setopen={resetErrorUserxColdex} subtext={resultMessagUserxColdex}></Alert>
      }
      {
        opeSecxUserxColdex &&
        <Alert type={!dataErroSecxUserxColdex ? 'SUCCESS' : 'ERROR'} open={opeSecxUserxColdex} text={shortMessagSecxUserxColdex} setopen={resetErrorSecxUserxColdex} subtext={resultMessagSecxUserxColdex}></Alert>
      }
      {
        opeRowData &&
        <Alert type={!dataErroRowData ? 'SUCCESS' : 'ERROR'} open={opeRowData} text={shortMessagRowData} setopen={resetErrorRowDataPublic} subtext={resultMessagRowData}></Alert>
      }
    </>
  )
}

export default Errors